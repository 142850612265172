.ButtonDisabled {
    margin-top: 20px;
    padding: 12px 25px;
    border-radius: 0px;
    background-color: gray;
    -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
}

.ValidationError {
    margin-bottom: 5px;
    font-size: 11px;
    position: static;
    width: auto;
    clear: none;
    opacity: 0.6;
    direction: ltr;
    text-align: left;
    border: 1px solid red;
}