/* DESKTOP */

@media (min-width: 499px) {
    .Modal {
        position: absolute;
        z-index: 500;
        background-color: white;
        width: 80%;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px black;
        padding: 16px;
        width: 500px;
        left: calc(50% - 250px);
        top: 30%;
        /* top: 0%;  */
        box-sizing: border-box;
        transition: all 0.3s ease-out;
        overflow-y: hidden;
    }
}

/* MOBILE */

@media (max-width: 499px) {
    .Modal {
        position: absolute;
        z-index: 500;
        background-color: white;
        width: 80%;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px black;
        padding: 16px;
        left: 10%;
        /* top: 30%; */
        top: 0%; 
        box-sizing: border-box;
        transition: all 0.3s ease-out;
        overflow-y: hidden;
    }
}