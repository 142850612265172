/* DESKTOP */

@media (min-width: 499px) {
    .Content {
        margin-top: 18px;
        /* background-color: #fffef9; */
    }
    .MobileOnly {
        display: none;
    }
}

/* MOBILE */

@media (max-width: 499px) {
    .Content {
        margin-top: 18px;
        /* background-color: #fffef9; */
    }
    .MobileOnly {
        width: 50px;
        float: right;
        top: -50px;
        height: 0px;
        position: relative;
        left: 0px;
        z-index: 5070;
    }
    .DesktopOnly {
        display: none;
    }
}