.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  font-family: Montserrat, sans-serif;
  color: #1a1b1f;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}

h1 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 44px;
  line-height: 62px;
  font-weight: 400;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 46px;
  font-weight: 400;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 38px;
  font-weight: 400;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}

p {
  margin-bottom: 10px;
}

a {
  display: block;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #1a1b1f;
  text-decoration: underline;
}

a:hover {
  color: #32343a;
}

a:active {
  color: #43464d;
}

ul {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 40px;
  list-style-type: disc;
}

li {
  margin-bottom: 10px;
}

img {
  display: block;
}

label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

blockquote {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 15px 30px;
  border-left: 5px solid #e2e2e2;
  font-size: 20px;
  line-height: 34px;
}

figure {
  margin-top: 25px;
  padding-bottom: 20px;
}

figcaption {
  margin-top: 5px;
  opacity: 0.6;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.divider {
  height: 1px;
  background-color: #eee;
}

.styleguide-content-wrap {
  text-align: center;
}

.section {
  margin-right: 30px;
  margin-left: 30px;
}

.section.cc-cta {
  padding-right: 80px;
  padding-left: 80px;
  background-color: #f5f5f5;
}

.styleguide-block {
  display: block;
  margin-top: 80px;
  margin-bottom: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.paragraph-tiny {
  font-size: 12px;
  line-height: 20px;
}

.paragraph-bigger {
  margin-bottom: 10px;
  opacity: 1;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
}

.paragraph-bigger.cc-bigger-light {
  opacity: 0.6;
}

.button {
  margin-top: 20px;
  padding: 12px 25px;
  border-radius: 0px;
  background-color: #1a1b1f;
  -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  background-color: #32343a;
  color: #fff;
}

.button:active {
  background-color: #43464d;
}

.button.cc-jumbo-button {
  margin-bottom: 20px;
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-contact-us {
  position: relative;
  z-index: 5;
}

.button.cc-white-button {
  padding: 16px 35px;
  background-color: #fff;
  color: #212121;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-white-button:hover {
  background-color: hsla(0, 0%, 100%, .8);
}

.button.cc-white-button:active {
  background-color: hsla(0, 0%, 100%, .9);
}

.label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.label.cc-styleguide-label {
  margin-bottom: 25px;
}

.label.cc-light {
  opacity: 0.6;
}

.label.cc-blog-date {
  margin-top: 20px;
  opacity: 0.6;
}

.heading-jumbo-tiny {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
}

.rich-text {
  width: 70%;
  margin-right: auto;
  margin-bottom: 100px;
  margin-left: auto;
}

.rich-text p {
  margin-top: 15px;
  margin-bottom: 25px;
  opacity: 0.6;
}

.paragraph-light {
  position: static;
  width: auto;
  margin-top: 0px;
  clear: none;
  opacity: 0.6;
  direction: ltr;
  text-align: left;
}

.paragraph-light.right-aligned {
  margin-bottom: 10px;
  text-align: right;
}

.paragraph-light.ondertitel {
  margin-top: 20px;
  font-size: 11px;
}

.paragraph-light.profiellabel {
  display: inline-block;
  width: 200px;
  height: 30px;
}

.heading-jumbo {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
  text-align: center;
  text-transform: none;
}

.heading-jumbo-small {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 50px;
  font-style: italic;
  font-weight: 400;
  text-transform: none;
}

.styleguide-button-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.styleguide-header-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 460px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1a1b1f;
  color: #fff;
  text-align: center;
}

.paragraph-small {
  font-size: 14px;
  line-height: 26px;
}

.logo-link {
  z-index: 1;
}

.logo-link:hover {
  opacity: 0.8;
}

.logo-link:active {
  opacity: 0.7;
}

.menu {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navigation-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.navigation-item {
  padding-top: 9px;
  padding-bottom: 9px;
  opacity: 0.6;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.navigation-item:hover {
  opacity: 0.9;
}

.navigation-item:active {
  opacity: 0.8;
}

.navigation-item.w--current {
  opacity: 1;
  color: #1a1b1f;
  font-weight: 600;
}

.navigation-item.w--current:hover {
  opacity: 0.8;
  color: #32343a;
}

.navigation-item.w--current:active {
  opacity: 0.7;
  color: #32343a;
}

.navigation-item.login {
  display: none;
}

.logo-image {
  display: block;
}

.navigation-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.footer-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 70px;
  padding-bottom: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.webflow-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.5;
  -webkit-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
  text-decoration: none;
}

.webflow-link:hover {
  opacity: 1;
}

.webflow-link:active {
  opacity: 0.8;
}

.webflow-logo-tiny {
  margin-top: -2px;
  margin-right: 8px;
}

.cta-text {
  width: 70%;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;
}

.cta-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 110px;
  padding-bottom: 120px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.intro-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 620px;
  margin-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f4f4f4;
  background-image: url('../images/Wielrennen-in-Zeeland.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.intro-header.cc-subpage {
  height: 480px;
  background-image: url('../images/Amateur-Wielrennen.jpeg');
  background-position: 0px 0px, 0px 0px;
  background-size: cover, 125px;
}

.intro-content {
  width: 70%;
  max-width: 1140px;
  background-color: #fff;
  opacity: 0.95;
  text-align: center;
}

.intro-content.cc-homepage {
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 #000;
  opacity: 0.95;
}

.motto-wrap {
  width: 80%;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  text-align: center;
}

.about-story-wrap {
  width: 80%;
  margin: 80px auto;
  text-align: center;
}

.our-services-grid {
  margin-bottom: 120px;
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  -ms-grid-rows: auto 60px auto;
  grid-template-areas: ". . ." ". . .";
  -ms-grid-columns: 1fr 80px 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.section-heading-wrap {
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}

.service-icon {
  margin-bottom: 30px;
}

.status-message {
  padding: 9px 30px;
  background-color: #212121;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.status-message.cc-success-message {
  background-color: hsla(156.8674698795181, 82.18%, 39.61%, 1.00);
}

.status-message.cc-error-message {
  background-color: #db4b68;
}

.status-message.cc-no-data {
  display: block;
  width: 70%;
  margin: 100px auto;
}

.contact-form-wrap {
  padding: 45px 50px 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #ededed;
}

.contact-form-grid {
  margin-top: 100px;
  margin-bottom: 100px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 2.5fr 80px 1fr;
  grid-template-columns: 2.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.details-wrap {
  margin-bottom: 30px;
}

.get-in-touch-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.text-field {
  margin-bottom: 18px;
  padding: 21px 20px;
  border: 1px solid #e4e4e4;
  border-radius: 0px;
  -webkit-transition: border-color 400ms ease;
  transition: border-color 400ms ease;
  font-size: 14px;
  line-height: 26px;
}

.text-field:hover {
  border-color: #e3e6eb;
}

.text-field:active {
  border-color: #43464d;
}

.text-field:focus {
  border-color: #43464d;
}

.text-field::-webkit-input-placeholder {
  color: rgba(50, 52, 57, .4);
}

.text-field:-ms-input-placeholder {
  color: rgba(50, 52, 57, .4);
}

.text-field::-ms-input-placeholder {
  color: rgba(50, 52, 57, .4);
}

.text-field::placeholder {
  color: rgba(50, 52, 57, .4);
}

.text-field.cc-contact-field {
  margin-bottom: 25px;
}

.text-field.cc-textarea {
  height: 200px;
  padding-top: 12px;
}

.contact-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.contact-form-heading-wrap {
  margin-bottom: 40px;
}

.contact-heading {
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 400;
}

.map {
  height: 460px;
  margin-bottom: 100px;
  line-height: 20px;
}

.contact-team-name-wrap {
  margin-bottom: 30px;
}

.our-contacts {
  margin-bottom: 120px;
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  grid-template-areas: ". . .";
  -ms-grid-columns: 1fr 80px 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: center;
}

.contact-team-details-wrap {
  margin-top: 30px;
}

.contact-team-pic {
  height: 150px;
  margin-bottom: 30px;
  background-color: #f5f5f5;
}

.team-pic {
  width: 100%;
  height: 420px;
  margin-bottom: 40px;
  background-color: #f4f4f4;
}

.team-members {
  margin-bottom: 60px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-rows: auto 30px auto;
  grid-template-areas: ". . ." ". . .";
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.team-member-title-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.team-member-name {
  margin-bottom: 5px;
  opacity: 1;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
}

.projects-grid {
  margin-bottom: 60px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-areas: "." "." ".";
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto 30px auto 30px auto;
  grid-template-rows: auto auto auto;
}

.project-name-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.project-name-link {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  text-decoration: none;
}

.project-name-link:hover {
  opacity: 0.8;
}

.project-name-link:active {
  opacity: 0.7;
}

.project-cover-link {
  margin-bottom: 40px;
}

.project-cover-link:hover {
  opacity: 0.8;
}

.project-cover-link:active {
  opacity: 0.7;
}

.project-overview-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 620px;
  margin-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f4f4f4;
  background-image: url('../images/portfolio-1---wide.svg');
  background-position: 0px 0px;
  background-size: cover;
}

.project-details-grid {
  margin-top: 100px;
  margin-bottom: 100px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 1fr 80px 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.project-pics {
  margin-bottom: 120px;
}

.detail-image {
  width: 100%;
  margin-bottom: 30px;
}

.blog-detail-header-wrap {
  width: 70%;
  margin: 60px auto;
}

.detail-header-image {
  display: block;
  width: 100%;
  height: 620px;
  margin-bottom: 60px;
  /* background-image: url('./../images/example-bg.png'); */
  background-position: 0px 0px;
  background-size: cover;
}

.detail-header-image.w--current {
  margin-bottom: 60px;
}

.blog-list-wrap {
  margin-bottom: 100px;
}

.blog-item {
  display: block;
  margin-right: auto;
  margin-bottom: 80px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.blog-preview-image {
  display: block;
  width: 100%;
  height: 620px;
  margin-bottom: 45px;
  background-position: 0px 0px;
  background-size: cover;
  -webkit-transition: opacity 600ms ease;
  transition: opacity 600ms ease;
}

.blog-preview-image:hover {
  opacity: 0.8;
}

.blog-preview-image:active {
  opacity: 0.7;
}

.blog-summary-wrap {
  width: 70%;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  text-align: left;
}

.blog-heading-link {
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 46px;
  font-weight: 400;
  text-decoration: none;
}

.blog-heading-link:hover {
  opacity: 0.8;
}

.blog-heading-link:active {
  opacity: 0.7;
}

.contact-email-link {
  margin-bottom: 5px;
  opacity: 0.6;
}

.contact-email-link:hover {
  opacity: 1;
}

.contact-email-link:active {
  opacity: 0.8;
}

.protected-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.protected-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 90px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.protected-heading {
  margin-bottom: 30px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  padding: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

._404-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1a1b1f;
}

._404-content-wrap {
  margin-bottom: 20px;
}

.home-content-wrap {
  margin-top: 80px;
  margin-bottom: 120px;
}

.home-section-wrap {
  margin-bottom: 30px;
}

.section-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.about-grid {
  margin-bottom: 80px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 1fr 80px 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.about-grid.cc-about-2 {
  grid-template-areas: ". .";
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
}

.intro-text {
  width: 80%;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;
}

.collection-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.work-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.blog-heading {
  margin-bottom: 60px;
  text-align: center;
}

.blog-preview-wrap {
  width: 33.33%;
  padding-right: 45px;
  padding-bottom: 30px;
  padding-left: 15px;
}

.collection-list-wrapper {
  margin-bottom: 120px;
}

.business-article-heading {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  text-decoration: none;
}

.business-article-heading:hover {
  opacity: 0.8;
}

.business-article-heading:active {
  opacity: 0.7;
}

.text-block {
  font-family: 'Varela Round', sans-serif;
  font-size: 35px;
  font-style: normal;
}

.image {
  background-image: url('../images/Wielrennen-in-Zeeland.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.image-2 {
  background-image: url('../images/Amateur-Wielrennen-png.png');
  background-position: 0px 0px;
  background-size: cover;
}

.div-block {
  width: 80%;
  min-height: 300px;
  margin-right: auto;
  margin-left: auto;
}

.image-3 {
  margin-right: auto;
  margin-left: auto;
}

.div-block-2 {
  width: 75%;
  min-height: 200px;
  margin: 40px auto;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, .4);
  border-radius: 1px;
}

.image-4 {
  width: 100%;
  max-height: 150px;
}

.list {
  list-style-type: none;
}

.button-2 {
  width: 100%;
  text-align: center;
}

.image-5 {
  float: left;
  background-image: url('../images/star.png');
  background-position: 0px 0px;
  background-size: auto;
}

.image-6 {
  display: inline-block;
  float: left;
}

.image-7 {
  float: left;
}

.image-8 {
  background-image: url('../images/boy.png');
  background-position: 0px 0px;
  background-size: 125px;
}

.list-2 {
  list-style-type: none;
}

.image-9 {
  float: left;
}

.button-3 {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 0px;
  background-color: #ed9b02;
  opacity: 0.95;
  text-align: center;
}

.column {
  padding-left: 0px;
}

.button-4 {
  width: 100%;
  text-align: center;
}

.text-block-2 {
  font-size: 12px;
}

.text-field-2 {
  display: inline-block;
  width: 75px;
}

.button-5 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.div-block-4 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.text-field-3 {
  width: 95%;
}

.text-field-4 {
  width: 100%;
  margin-left: auto;
}

.button-6 {
  background-color: hsla(38.97435897435897, 98.32%, 46.67%, 1.00);
}

.submit-button {
  width: 100%;
  background-color: #ecbf36;
}

.heading-2 {
  text-align: center;
}

.button-7 {
  width: 80%;
  height: 100%;
  max-height: 100%;
  float: right;
  background-color: #11a211;
  text-align: center;
}

.columns {
  margin-top: 1px;
  padding-left: 0px;
}

.text-block-3 {
  margin-top: 10px;
  margin-bottom: auto;
}

.text-block-4 {
  /* padding-left: 10px; */
}

.text-block-5 {
  padding-left: 10px;
}

.text-block-6 {
  padding-left: 10px;
}

.heading-3 {
  margin-top: auto;
  margin-bottom: 0px;
}

.heading-4 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.afmeldenbutton {
  width: 80%;
  float: right;
  background-color: #ec363f;
  text-align: center;
}

.columns-2 {
  margin-top: 10px;
}

.columns-3 {
  margin-top: 10px;
}

.submit-button-2 {
  width: 100%;
  text-align: center;
}

.form {
  margin-top: 20px;
}

@media (max-width: 991px) {
  .section.cc-cta {
    padding-right: 0px;
    padding-left: 0px;
  }
  .styleguide-block {
    text-align: center;
  }
  .button {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .button.cc-contact-us {
    display: none;
  }
  .heading-jumbo {
    font-size: 56px;
    line-height: 70px;
  }
  .logo-link.w--current {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .menu-icon {
    display: block;
  }
  .menu {
    position: static;
    margin-left: 30px;
  }
  .navigation-wrap {
    background-color: #fff;
  }
  .navigation {
    padding: 25px 30px;
  }
  .navigation-item {
    padding: 15px 30px;
    -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    text-align: center;
  }
  .navigation-item:hover {
    background-color: #f7f8f9;
  }
  .navigation-item:active {
    background-color: #eef0f3;
  }
  .menu-button {
    padding: 0px;
  }
  .menu-button.w--open {
    background-color: transparent;
  }
  .navigation-items {
    background-color: #fff;
  }
  .cta-text {
    width: auto;
  }
  .cta-wrap {
    width: auto;
    padding: 80px 50px 90px;
  }
  .our-services-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-template-areas: ". ." ". ." ". .";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
    text-align: center;
  }
  .service-icon {
    display: inline-block;
  }
  .contact-form-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 50px;
    grid-template-areas: "." ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }
  .get-in-touch-form {
    text-align: left;
  }
  .our-contacts {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-template-areas: "." "." ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }
  .team-members {
    grid-template-areas: ". ." ". ." ". .";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }
  .team-member-title-wrap {
    margin-bottom: 30px;
  }
  .project-details-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 50px;
    grid-template-areas: "." ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }
  .blog-detail-header-wrap {
    width: 70%;
  }
  .detail-header-image {
    height: 460px;
  }
  .blog-item {
    width: 80%;
  }
  .blog-preview-image {
    height: 460px;
  }
  .blog-summary-wrap {
    width: 100%;
  }
  .about-grid {
    grid-row-gap: 50px;
    grid-template-areas: "." ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }
  .about-grid.cc-about-2 {
    grid-template-areas: "." ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .intro-text {
    width: auto;
  }
  .collection-wrap {
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .blog-preview-wrap {
    width: 80%;
    padding-right: 15px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .section {
    margin-right: 15px;
    margin-left: 15px;
  }
  .section.cc-cta {
    padding: 15px;
  }
  .section.cc-home-wrap {
    margin-right: 15px;
    margin-left: 15px;
  }
  .section.cc-store-home-wrap {
    margin-right: 15px;
    margin-left: 15px;
  }
  .container {
    text-align: center;
  }
  .paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
  }
  .rich-text {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }
  .heading-jumbo {
    font-size: 50px;
    line-height: 64px;
  }
  .heading-jumbo-small {
    font-size: 30px;
    line-height: 52px;
  }
  .logo-link {
    padding-left: 0px;
  }
  .navigation {
    padding: 20px 30px;
  }
  .cta-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
  .intro-content {
    width: 80%;
  }
  .our-services-grid {
    grid-row-gap: 60px;
    grid-template-areas: "." "." "." "." "." ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 60px auto 60px auto 60px auto 60px auto 60px auto;
    grid-template-rows: auto auto auto auto auto auto;
  }
  .status-message.cc-no-data {
    width: 80%;
    max-width: 470px;
  }
  .contact-form-wrap {
    padding: 30px;
  }
  .text-field.cc-contact-field {
    text-align: left;
  }
  .text-field.cc-textarea {
    text-align: left;
  }
  .team-pic {
    height: 300px;
    margin-bottom: 30px;
  }
  .team-member-name {
    font-size: 16px;
    line-height: 28px;
  }
  .project-name-wrap {
    margin-bottom: 30px;
  }
  .project-name-link {
    font-size: 16px;
    line-height: 28px;
  }
  .detail-image {
    margin-bottom: 15px;
  }
  .blog-detail-header-wrap {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }
  .blog-item {
    width: 90%;
    max-width: 470px;
  }
  .blog-summary-wrap {
    text-align: center;
  }
  .utility-page-wrap {
    padding: 15px;
  }
  ._404-wrap {
    padding: 30px;
  }
}

@media (max-width: 479px) {
  h1 {
    font-size: 36px;
    line-height: 52px;
  }
  .rich-text {
    width: 100%;
    max-width: none;
  }
  .heading-jumbo {
    font-size: 36px;
    line-height: 48px;
  }
  .menu {
    margin-left: 15px;
  }
  .navigation {
    padding-right: 20px;
    padding-left: 20px;
  }
  .navigation-item.login {
    display: block;
  }
  .menu-button {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .menu-button.w--open {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .cta-wrap {
    padding-right: 15px;
    padding-left: 15px;
  }
  .intro-header.cc-subpage {
    height: 100px;
    background-image: url('../images/Amateur-Wielrennen.jpeg');
    background-position: 0px 0px;
    background-size: cover;
  }
  .status-message.cc-no-data {
    width: 100%;
  }
  .contact-form-wrap {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .contact-form-grid {
    width: 100%;
  }
  .our-contacts {
    width: 90%;
  }
  .team-members {
    grid-template-areas: "." "." "." "." "." ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
  }
  .project-details-grid {
    width: 100%;
  }
  .blog-detail-header-wrap {
    width: 100%;
    max-width: none;
  }
  .blog-item {
    width: 100%;
    max-width: none;
  }
  .image {
    width: 100%;
  }
  .image-2 {
    width: 100%;
  }
  .div-block-2 {
    width: 95%;
  }
  .list {
    padding-left: 0px;
  }
  .image-7 {
    width: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  .column-2 {
    padding-left: 0px;
  }
  .column-3 {
    padding-left: 0px;
  }
  .column-4 {
    padding-left: 0px;
  }
  .field-label {
    margin-top: 20px;
  }
  .button-4 {
    width: 100%;
  }
  .text-block-2 {
    text-align: center;
  }
  .column-5 {
    border-bottom: 1px solid #ededed;
  }
  .image-10 {
    margin-right: auto;
    margin-left: auto;
  }
  .div-block-3 {
    width: 150px;
    margin-right: auto;
    margin-left: auto;
  }
  .heading {
    border-top: 1px solid #ededed;
  }
  .link {
    margin-bottom: 20px;
    border-bottom: 1px solid #ededed;
  }
}

#w-node-76c147234d34-185bfbd6 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 991px) {
  #w-node-76c147234d34-185bfbd6 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
}

#w-node-76c147234d3f-185bfbd6 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 991px) {
  #w-node-76c147234d3f-185bfbd6 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-76c147234d3f-185bfbd6 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}

#w-node-76c147234d41-185bfbd6 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 991px) {
  #w-node-76c147234d41-185bfbd6 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
}

#w-node-76c147234d4c-185bfbd6 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 991px) {
  #w-node-76c147234d4c-185bfbd6 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-76c147234d4c-185bfbd6 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}

#w-node-509be705858e-5e5bfbee {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 479px) {
  #w-node-509be705858e-5e5bfbee {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}

#w-node-509be70585a6-5e5bfbee {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 479px) {
  #w-node-509be70585a6-5e5bfbee {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
}

#w-node-509be705858e-c8e2810c {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 479px) {
  #w-node-509be705858e-c8e2810c {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}

#w-node-509be70585a6-c8e2810c {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 991px) {
  #w-node-509be70585a6-c8e2810c {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
}

@media screen and (max-width: 479px) {
  #w-node-509be70585a6-c8e2810c {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
}

#w-node-509be705858e-01d321b3 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-509be70585a6-01d321b3 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 991px) {
  #w-node-509be70585a6-01d321b3 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
}